import React from 'react';
import styled from '@emotion/styled'
import RichText from 'components/RichText';
import Content from 'components/_ui/Content';
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import { Inner } from 'styles/structure';

const HeroContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;

  & + * {
    margin-top: 5.6rem;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    & + * {
      margin-top: 8.8rem;
    }
  }
`;

const HeroInner = styled(Inner)`
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    align-items: center;
  }
`;

const HeroIcon = styled.img`
  display: block;
  width: 8rem;
  margin: 0 auto 1.6rem;
  flex: 0 0 14.6rem;

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    width: 14.6rem;
    margin: 0 5.6rem 0 0;
  }
`

const HeroContent = styled.div`
  /* minor display correction for clipped headings without content */
  padding-bottom: 0.4rem;
  
  header {
    text-align: center;
    
    & + * {
      margin-top: 2.4rem;
    }
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: ${colors.teal800};
      line-height: 1;
    }
  }
  
  p {
    color: ${colors.teal800};
    max-width: 76.8rem;
    text-align: center;
  }

  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    header {
      text-align: left;

      & + * {
        margin-top: 1.6rem;
      }
    }
    
    p {
      font-size: 2.2rem;
      text-align: left;
    }
  }
`;

const ProgramsHero = ({ heading, content, icon }) => {
  let heroHeading = '';

  if (heading && heading.raw) {
    heroHeading = <RichText render={heading.raw}/>;
  }

  return (
    <HeroContainer>
      <HeroInner>
        {icon && (
          <HeroIcon
            src={icon.url}
            alt={icon.alt}
          />
        )}

        <HeroContent>
          {heading && (
            <header>
              {heroHeading}
            </header>
          )}

          {content && <Content content={content.raw}/>}
        </HeroContent>
      </HeroInner>
    </HeroContainer>
  );
};

export default ProgramsHero;
