import React, { Component } from 'react';
import styled from '@emotion/styled'
import dimensions from 'styles/dimensions';

const GridContainer = styled.div`
  display: block;
  width: 100%;
  background-color: #fff;
`;

const GridList = styled.ul`
  li {
    max-width: 60rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    
    & + * {
      margin-top: 3.2rem;
    }
  }
  
  @media(min-width: ${dimensions.tabletPortraitUp}px) {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    
    li {
      flex: 0 0 50%;
      width: 50%;
      
      & + * {
        margin-top: 0;
      }
      
      &:nth-of-type(n+3) {
        margin-top: 6.4rem;
      }
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    
    li {
      flex: 0 0 33.3334%;
      width: 33.3334%;
      
      &:nth-of-type(n+3) {
        margin-top: 0;
      }
      
      &:nth-of-type(n+4) {
        margin-top: 6.4rem;
      }
    }
  }
`;

class ProgramsGrid extends Component {
  render() {
    return (
      <GridContainer>
        <GridList>
          {this.props.children}
        </GridList>
      </GridContainer>
    );
  }
}

export default ProgramsGrid;
