import React from 'react';
import styled from '@emotion/styled'
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import { Inner } from 'styles/structure';
import ProgramAreasHero from 'components/_page/programs/ProgramsHero';
import ProgramCard from 'components/_card/Program';
import ProgramsGrid from 'components/_grid/Programs';

const OurProgramsContainer = styled.div`
  background-color: ${colors.grey100};
  background-image: url("../images/pattern_tiled-hex.png");
  background-size: 720px 263px;
  background-position: -24px -24px;
  background-repeat: repeat-x;
  padding: 6.4rem 0;
  box-shadow: 0 0.2rem 0.4rem inset rgba(0,0,0,0.05);
  
  & + * {
    margin-top: 5.6rem;
  }
  
  @media (min-width: ${dimensions.tabletLandscapeUp}px) {
    background-size: 1440px 526px;
    
    & + * {
      margin-top: 8.8rem;
    }  
  }
`;

const OurPrograms = ({ hero_icon, hero_heading, hero_content, programArms }) => {
  return (
    <OurProgramsContainer>
      <ProgramAreasHero
        heading={hero_heading}
        content={hero_content}
        icon={hero_icon}
      />

      <Inner>
        <ProgramsGrid>
          {programArms.map((programArm, i) => (
            <li key={`program-card-${i}`}>
              <ProgramCard
                color={programArm.item.document.data.color}
                image={programArm.item.document.data.tout_image}
                icon={programArm.item.document.data.icon}
                heading={programArm.item.document.data.title.text}
                content={programArm.item.document.data.tout_text}
                uid={programArm.item.document.uid}
              />
            </li>
          ))}
        </ProgramsGrid>
      </Inner>
    </OurProgramsContainer>
  );
};

export default OurPrograms;
