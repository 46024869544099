import React from "react";
import { Link } from "gatsby";
import Content from "components/_ui/Content";
import styled from '@emotion/styled'
import colors from 'styles/colors';
import dimensions from 'styles/dimensions';
import ArrowLink from 'components/_ui/ArrowLink';

const CardContainer = styled(Link)`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
  transition: background 0.12s ease-in-out;
  
  header {
    & + * {
      flex: 1 1 100%;
      margin-top: 1.6rem;
    }
  }
  
  &:hover {
    background-color: ${props => (props.color)};
    
    img {
      opacity: 1;
    }
    
    h1, h2, h3, h4, h5, h6, p, span {
      color: #fff;
    }
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    header {
      & + * {
        margin-top: 0;
      }
    }
  }
`;

const CardHeader = styled.header`
  position: relative;
  width: 100%;
  flex: 0 0 auto;
  text-align: center;
  
  &::after {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 66.6667%;
  }
  
  div {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
    padding: 0 1.6rem;
  }
  
  h3 {
    color: #fff;
    font-size: 2.4rem;
    line-height: 1.02;
    font-weight: 500;
    letter-spacing: -0.016em;
    transition: color 0.12s ease-in-out;
  }

  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    div {
      padding: 0 3.2rem;
    }
    
    h3 {
      color: ${colors.teal800};
      font-size: 3.5rem;
    }
  }
`;

const CardImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.32s ease-in-out;

  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    opacity: 0;
  }
`;

const CardIcon = styled.img`
  margin-bottom: 1.2rem;
`;

const CardContent = styled.div`
  padding: 4rem 3.2rem;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    padding: 4rem 5.6rem 5.6rem;
  }
`;

const CardCTA = styled.div`
  flex: 0 0 auto;
`;

const ProgramCard = ({ color, image, icon, heading, content, uid }) => (
  <CardContainer to={`/program-area/${uid}`} color={color}>
    <CardHeader>
      {image && (
        <CardImage src={image.url} alt={image.alt} />
      )}

      <div>
        {icon && (
          <CardIcon src={icon.url} alt={icon.alt} />
        )}

        {heading && (
          <h3>{heading}</h3>
        )}
      </div>
    </CardHeader>


    <CardContent>
      {content && content.text && <Content content={content.raw}/>}

      <CardCTA>
        <ArrowLink text="View Program Area" />
      </CardCTA>
    </CardContent>
  </CardContainer>
);

export default ProgramCard;
