import React from 'react';
import { graphql } from 'gatsby';
import Seo from 'components/Seo';
import Layout from 'components/Layout';
import TrapdoorGrid from 'components/_grid/Trapdoor';
import TrapdoorCard from 'components/_card/Trapdoor';
import OurPrograms from 'components/_page/programs/OurPrograms';
import { withPreview } from 'gatsby-source-prismic';

const RenderBody = ({ programs }) => (
  <>
    <OurPrograms
      hero_heading={programs.hero_heading}
      hero_content={programs.hero_content}
      hero_icon={programs.hero_icon}
      programArms={programs.program_arms}
    />

    <TrapdoorGrid variant={'programs'}>
      {programs.trapdoors.slice(0,2).map((card, i) => (
        <li key={`trapdoor-${i}`}>
          <TrapdoorCard
            image={card.hover_image}
            heading={card.heading}
            content={card.content}
            buttonText={card.button_text}
            destination={card.destination}
            variant='background'
            color='white'
          />
        </li>
      ))}
    </TrapdoorGrid>
  </>
);

const ProgramsPage = ({ data }) => {
  //Required check for no data being returned
  const programs = data.allPrismicPrograms.edges[0].node.data;

  return (
    <Layout>
      <Seo
        title={programs.social_title || 'Programs'}
        description={programs.social_description}
        image={programs.social_image}
        bodyClass={"has-hex-bg"}
      />

      <RenderBody programs={programs} />
    </Layout>
  );
};

export const query = graphql`
  {
    allPrismicPrograms {
      edges {
        node {
          data {
            hero_icon {
              url
            }
            hero_heading {
              raw
              text
            }
            hero_content {
              raw
            }
            program_arms {
              item {
                document {
                  ... on PrismicProgramArm {
                    uid
                    data {
                      title {
                        text
                        raw
                      }
                      icon {
                        url
                        alt
                      }
                      color
                      tout_image {
                        url
                        alt
                      }
                      tout_text {
                        raw
                        text
                      }
                    }
                  }
                }
              }
            }
            trapdoors {
              hover_image {
                url
                alt
              }
              heading {
                raw
              }
              content {
                raw
                text
              }
              button_text
              destination
            }
          }
        }
      }
    }
  }
`;

export default withPreview(ProgramsPage);